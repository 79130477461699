import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import useMobileMode from '../../../hooks/useMobileMode';
import HeaderSostainibility from '../../molecules/header-sostainibility/header-sostainibility';
import { siteUrl } from '../../../shared/utils/siteUrl';
import {removeDoubleSlash} from '../../../shared/utils/urlUtil'
import './sustainibility-content.scss';
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil'

export default () => {
  const data = useStaticQuery(graphql`
    query SUSTAINIBILITY_QUERY {
      allContentfulNosotros {
        nodes {
          node_locale
          environmentalSustainability {
            banner {
              title
              description
              file {
                url
              }
            }
            mainTitle
            descripcionCorta {
              json
            }
            subpaginas {
              nombreCategoria
              ctaCategoriaHome
              logoCategoria {
                description
                title
                file {
                  url
                }
              }
              imagenCategoriaHome {
                description
                title
                file {
                  url
                }
              }
              descripcionCategoriaHome {
                json
              }
              slug
            }
          }
        }
      }
    }
  `);

  const { isMobile } = useMobileMode();
  const dataFiltered = data.allContentfulNosotros.nodes?.filter(content =>
    content.node_locale.includes(process.env.LOCALE)
  )[0]?.environmentalSustainability;

  const datalayerSustainibilityCard = (labelText) => {      
    let location=''
    if (typeof window !== 'undefined') {
        location= window.location.href
    }
    pushDataLayerEvent({
      event: 'Interacciones_Sostenibilidad',
      category: 'Nosotros',
      action: 'click',
      label: labelText,
      sub_category: 'Sostenibilidad',
      second_sub_category: labelText,
      location: location,
      user_data: [],
      log_status: '',
    })
  }

  return (
    <div className="f-sustainibility-container">
      <HeaderSostainibility
        banner={dataFiltered.banner}
        title={dataFiltered.mainTitle}
      />

      <div className="f-sustainibility-content">
        {dataFiltered.descripcionCorta && (
          <div className="f-sustainibility-content-description">
            {documentToReactComponents(dataFiltered.descripcionCorta.json)}
          </div>
        )}

        <div className="f-sustainibility-cards-container">
          {dataFiltered.subpaginas &&
            dataFiltered.subpaginas.map(card => (
              <div className="f-sustainibility-card">
                <div className="f-sustainibility-card-header">
                  <figure className="f-sustainibility-card-image">
                    <img
                      src={card.imagenCategoriaHome?.file.url}
                      alt={card.imagenCategoriaHome?.description}
                    />
                  </figure>
                  {card.logoCategoria && (
                    <figure className="f-sustainibility-card-logo">
                      <img
                        src={card.logoCategoria?.file.url}
                        alt={card.logoCategoria?.description}
                      />
                    </figure>
                  )}
                </div>
                <div className="f-sustainibility-card-body">
                  <h2 className="f-sustainibility-card-title">
                    {card.nombreCategoria}
                  </h2>

                  <div className="f-sustainibility-card-description">
                    {documentToReactComponents(
                      card.descripcionCategoriaHome.json
                    )}
                  </div>

                  {card.ctaCategoriaHome && (
                    <a
                      href={siteUrl + removeDoubleSlash('/nosotros/sostenibilidad/' + card.slug)}
                      className="f-sustainibility-card-button" onClick={() => datalayerSustainibilityCard(card.nombreCategoria)}
                    >
                      {card.ctaCategoriaHome}
                    </a>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
