import React from 'react';
import './header-sostainibility.scss';
import { siteUrl } from '../../../shared/utils/siteUrl';
import useMobileMode from '../../../hooks/useMobileMode';
import {removeDoubleSlash} from '../../../shared/utils/urlUtil'
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil';

const datalayerSustainibilityOption = (labelText) => {      
  let location=''
  if (typeof window !== 'undefined') {
      location= window.location.href
  }
  pushDataLayerEvent({
    event: 'Interacciones_Sostenibilidad',
    category: 'Nosotros',
    action: 'click',
    label: labelText,
    sub_category: 'Sostenibilidad',
    second_sub_category: labelText,
    location: location,
    user_data: [],
    log_status: '',
  })
}

const HeaderSostainibility = props => {
  const { isMobile } = useMobileMode();
  const { banner, title, menuInfo, activeOption } = props;

  return (
    <div className="f-sustainibility-header">
      <figure className="f-sustainibility-header-image">
        <img
          src={banner[isMobile ? 1 : 0]?.file.url}
          alt={banner[isMobile ? 1 : 0]?.description}
        />
      </figure>

      {(title || menuInfo) && (
        <div className="f-sustainibility-header-title">
          {title ? (
            <h1>{title}</h1>
          ) : (
            <div className="f-sustainibility-header-menu">
              {menuInfo.map((option, index) => (
                <a
                  className={
                    'f-sustainibility-header-menu-option' +
                    (activeOption === option.nombreCategoria ? ' active' : '')
                  }
                  href={siteUrl + removeDoubleSlash('/nosotros/sostenibilidad/' + option.slug)}
                  key={index}
                  onClick={() => datalayerSustainibilityOption(option.nombreCategoria)}
                >
                  <figure className="f-sustainibility-header-menu-option-image">
                    <img
                      src={option.logoCategoria.file.url}
                      alt={option.logoCategoria.description}
                    />
                  </figure>
                  <h2 className="f-sustainibility-header-menu-option-title">
                    {option.nombreCategoria}
                  </h2>
                </a>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default HeaderSostainibility;
